import "./index.scss";
import React from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap/";
import DialogBox from "../dialogBox";
import jahaj from "../../resources/images/arshdeep_2.png";
import cap from "../../resources/logos/uncapped.png";

const TeamCards = ({
  filteredPlayerData,
  teamName,
  isMobile,
  teamColor,
  team,
  secondaryTeamColor,
  secondaryTeamColorRGB,
  brightness,
  showDesign,
  teamColorRGB,
}) => {
  // filteredPlayerData = {
  //   Batsman: [0, 0, 0],
  //   Wicketkeeper: [0, 0, 0, 0, 0],
  //   Bowler: [0, 0, 0, 0, 0],
  //   Indians: [0, 0, 0, 0, 0],
  //   Overseas: [0, 0, 0, 0, 0],
  //   Uncapped: [0, 0, 0, 0, 0],
  //   "All Rounder": [0, 0, 0, 0, 0],
  // };

  let Batters = () => {
    let row = [];

    let length = filteredPlayerData["Batsman"]?.length;
    if (length < 5) length = 5;

    for (let i = 0; i < length; ++i) {
      let bat = filteredPlayerData["Batsman"][i];
      let Batsman = {};
      if (bat) Batsman = bat.Player;
      let BatsmanName = Batsman?.name?.split(" ");
      let BatsmanInitial = BatsmanName && BatsmanName[0][0];
      let BatsmanLastName = BatsmanName?.slice(1, BatsmanName.length)?.join(
        " "
      );

      row.push(
        <div
          style={{
            fontFamily: "Poppins-Medium",
            position: "relative",
            textWrap: "nowrap",
          }}
        >
          {Batsman?.nationality &&
            Batsman?.nationality?.toLowerCase() !== "india" && (
              <img
                src={jahaj}
                alt="batter"
                style={{
                  height: ".9rem",
                  position: "absolute",
                  left: "-17px",
                  top: "6px",
                }}
              />
            )}
          {Batsman?.uncapped && <img className="image" src={cap} />}
          {BatsmanInitial
            ? `${BatsmanInitial}. ${String(BatsmanLastName)}`
            : "-"}
        </div>
      );
    }

    return row;
  };

  let Wk = () => {
    let row = [];

    filteredPlayerData["Wicketkeeper"]?.forEach((wk) => {
      let Wicketkeeper = {};
      if (wk) Wicketkeeper = wk.Player;
      let WicketkeeperName = Wicketkeeper?.name?.split(" ");
      let WicketkeeperInitial = WicketkeeperName && WicketkeeperName[0][0];
      let WicketkeeperLastName = WicketkeeperName?.slice(
        1,
        WicketkeeperName.length
      )?.join(" ");

      row.push(
        <div style={{ fontFamily: "Poppins-Medium", position: "relative" }}>
          {Wicketkeeper?.nationality &&
            Wicketkeeper?.nationality?.toLowerCase() !== "india" && (
              <img
                src={jahaj}
                alt="Wk"
                style={{
                  height: ".9rem",
                  position: "absolute",
                  left: "-17px",
                  top: "6px",
                }}
              />
            )}
          {Wicketkeeper?.uncapped && <img className="image" src={cap} />}
          {WicketkeeperInitial
            ? `${WicketkeeperInitial}. ${String(WicketkeeperLastName)}`
            : "-"}
        </div>
      );
    });

    return row;
  };

  let Bowler = () => {
    let row = [];

    let length = filteredPlayerData["Bowler"]?.length;
    if (length < 5) length = 5;

    for (let i = 0; i < length; ++i) {
      let bowl = filteredPlayerData["Bowler"][i];
      let Bowler = {};
      if (bowl) Bowler = bowl.Player;
      let BowlerName = Bowler?.name?.split(" ");
      let BowlerInitial = BowlerName && BowlerName[0][0];
      let BowlerLastName = BowlerName?.slice(1, BowlerName.length)?.join(" ");

      row.push(
        <div style={{ fontFamily: "Poppins-Medium", position: "relative" }}>
          {Bowler?.nationality &&
            Bowler?.nationality?.toLowerCase() !== "india" && (
              <img
                src={jahaj}
                alt="bowler"
                style={{
                  height: ".9rem",
                  position: "absolute",
                  left: "-17px",
                  top: "6px",
                }}
              />
            )}
          {Bowler?.uncapped && <img className="image" src={cap} />}
          {BowlerInitial ? `${BowlerInitial}. ${String(BowlerLastName)}` : "-"}
        </div>
      );
    }

    return row;
  };

  let AllRounder = () => {
    let row = [];

    filteredPlayerData["All Rounder"]?.forEach((wk) => {
      let AllRounder = {};
      if (wk) AllRounder = wk.Player;
      let AllRounderName = AllRounder?.name?.split(" ");
      let AllRounderInitial = AllRounderName && AllRounderName[0][0];
      let AllRounderLastName = AllRounderName?.slice(
        1,
        AllRounderName.length
      )?.join(" ");

      row.push(
        <div style={{ fontFamily: "Poppins-Medium", position: "relative" }}>
          {AllRounder?.nationality &&
            AllRounder?.nationality?.toLowerCase() !== "india" && (
              <img
                src={jahaj}
                style={{
                  height: ".9rem",
                  position: "absolute",
                  left: "-17px",
                  top: "6px",
                }}
              />
            )}
          {AllRounder?.uncapped && <img className="image" src={cap} />}
          {AllRounderInitial
            ? `${AllRounderInitial}. ${String(AllRounderLastName)}`
            : "-"}
        </div>
      );
    });

    return row;
  };

  let Row1 = () => {
    let row = [],
      rowLength =
        filteredPlayerData?.["Batsman"]?.length >
        filteredPlayerData?.["Wicketkeeper"]?.length
          ? filteredPlayerData?.["Batsman"]?.length
          : filteredPlayerData?.["Wicketkeeper"]?.length;

    for (let i = 0; i < rowLength; ++i) {
      let Wicketkeeper = {},
        Batsman = {};
      if (filteredPlayerData["Batsman"][i])
        Batsman = filteredPlayerData["Batsman"][i].Player;
      let BatsmanName = Batsman?.name?.split(" ");
      let BatsmanInitial = BatsmanName && BatsmanName[0][0];
      let BatsmanLastName = BatsmanName?.slice(1, BatsmanName.length)?.join(
        " "
      );

      if (filteredPlayerData["Wicketkeeper"][i])
        Wicketkeeper = filteredPlayerData["Wicketkeeper"][i].Player;
      let WicketkeeperName = Wicketkeeper?.name?.split(" ");
      let WicketkeeperInitial = WicketkeeperName && WicketkeeperName[0][0];
      let WicketkeeperLastName = WicketkeeperName?.slice(
        1,
        WicketkeeperName.length
      )?.join(" ");

      row.push(
        <tr>
          <td>
            {Batsman?.nationality &&
              Batsman?.nationality?.toLowerCase() !== "india" && (
                <img src={jahaj} />
              )}
            {Batsman?.uncapped && <img className="image" src={cap} />}
            {BatsmanInitial
              ? `${BatsmanInitial}. ${String(BatsmanLastName)}`
              : "-"}
          </td>
          <td>
            {Wicketkeeper?.nationality &&
              Wicketkeeper?.nationality?.toLowerCase() !== "india" && (
                <img src={jahaj} />
              )}
            {Wicketkeeper?.uncapped && <img className="image" src={cap} />}
            {WicketkeeperInitial
              ? `${WicketkeeperInitial}. ${String(WicketkeeperLastName)}`
              : "-"}
          </td>
        </tr>
      );
    }

    return row;
  };

  let Row2 = () => {
    let row = [],
      rowLength =
        filteredPlayerData["Bowler"]?.length >
        filteredPlayerData["All Rounder"]?.length
          ? filteredPlayerData["Bowler"]?.length
          : filteredPlayerData["All Rounder"]?.length;

    for (let i = 0; i < rowLength; ++i) {
      let Bowler = {},
        AllRounder = {};
      if (filteredPlayerData["Bowler"][i])
        Bowler = filteredPlayerData["Bowler"][i].Player;
      let BowlerName = Bowler?.name?.split(" ");
      let BowlerInitial = BowlerName && BowlerName[0][0];
      let BowlerLastName = BowlerName?.slice(1, BowlerName.length)?.join(" ");

      if (filteredPlayerData["All Rounder"][i])
        AllRounder = filteredPlayerData["All Rounder"][i].Player;
      let AllRounderName = AllRounder?.name?.split(" ");
      let AllRounderInitial = AllRounderName && AllRounderName[0][0];
      let AllRounderLastName = AllRounderName?.slice(
        1,
        AllRounderName.length
      )?.join(" ");

      row.push(
        <tr>
          <td>
            {Bowler?.nationality &&
              Bowler?.nationality?.toLowerCase() !== "india" && (
                <img src={jahaj} />
              )}
            {Bowler?.uncapped && (
              <span>
                <img src={cap} className="image" />
              </span>
            )}
            {BowlerInitial
              ? `${BowlerInitial}. ${String(BowlerLastName)}`
              : "-"}
          </td>
          <td>
            {AllRounder?.nationality &&
              AllRounder?.nationality?.toLowerCase() !== "india" && (
                <img src={jahaj} />
              )}
            {AllRounder?.uncapped && <img className="image" src={cap} />}
            {AllRounderInitial
              ? `${AllRounderInitial}. ${String(AllRounderLastName)}`
              : "-"}
          </td>
        </tr>
      );
    }
    return row;
  };

  const brightnessOfColor =
    brightness ||
    Math.round(
      (parseInt(teamColorRGB?.r) * 299 +
        parseInt(teamColorRGB?.g) * 587 +
        parseInt(teamColorRGB?.b) * 114) /
        1000
    );

  //  // console.log(filteredPlayerData);
  return (
    <DialogBox
      logoSmall={`/teams/${team?.image}`}
      backgroundColor={teamColor ? teamColor : "#0040a8"}
      secondaryBackgroundColor={secondaryTeamColor ? secondaryTeamColor : "red"}
      showDesign={showDesign}
      header={
        <span
          style={{
            marginLeft: "1rem",
            color: brightnessOfColor > 125 ? "black" : "white",
            // color: "#fff",
            fontFamily: "Poppins-bold",
          }}
        >
          {teamName}
        </span>
      }
      headerStyle={{
        width: "100%",
        padding: "0.3rem",
        minHeight: "2rem",
        justifyContent: "left",
        maxWidth: "100%",
        margin: 0,
        fontSize: "1.5rem",
        fontFamily: "Poppins-Black !important",
      }}
      style={{
        minHeight: "31rem",
        width: "100%",
        alignContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor: "#FFF",
        fontFamily: "Poppins-Semibold",
        maxWidth: "100%",
        margin: 0,
        padding: "1.3rem",
      }}
      wrapperStyle={{
        maxWidth: "100%",
        width: "100%",
        minWidth: "1rem",
      }}
    >
      <Row
        style={{
          flexDirection: "column",
          width: "50%",
        }}
      >
        <Col
          style={{
            color: "black",
            textAlign: "left",
            position: "relative",
            fontSize: "1rem",
            marginBottom: ".8rem",
          }}
        >
          <div
            class="rectangle"
            style={{
              left: "-6px",
              top: "6px",
              position: "absolute",
              height: "1.2rem",
              width: "3px",
              backgroundColor: teamColor ? teamColor : "#0040a8",
            }}
          />
          <div
            style={{
              width: "100%",
              fontSize: "1.3rem",
              paddingBottom: ".5rem",
            }}
          >
            BATTERS
          </div>

          {Batters()}
        </Col>
        <Col
          style={{
            color: "black",
            position: "relative",
            textAlign: "left",
            fontSize: "1rem",
          }}
        >
          <div
            class="rectangle"
            style={{
              left: "-6px",
              top: "6px",
              position: "absolute",
              height: "1.2rem",
              width: "3px",
              backgroundColor: secondaryTeamColor
                ? secondaryTeamColor
                : "#0040a8",
            }}
          />
          <div
            style={{
              width: "100%",
              fontSize: "1.3rem",
              paddingBottom: ".5rem",
            }}
          >
            {" "}
            WK
          </div>
          {Wk()}
        </Col>
      </Row>
      <Row
        style={{
          flexDirection: "column",
          width: "50%",
        }}
      >
        <Col
          style={{
            position: "relative",
            color: "black",
            marginBottom: ".8rem",
            textAlign: "left",
            fontSize: "1rem",
          }}
        >
          <div
            class="rectangle"
            style={{
              left: "-6px",
              top: "6px",
              position: "absolute",
              height: "1.2rem",
              width: "3px",
              backgroundColor: teamColor ? teamColor : "#0040a8",
            }}
          />
          <div
            style={{
              width: "100%",
              fontSize: "1.3rem",
              paddingBottom: ".5rem",
            }}
          >
            BOWLERS
          </div>
          {Bowler()}
        </Col>
        <Col
          style={{
            color: "black",
            position: "relative",
            textAlign: "left",
            fontSize: "1rem",
          }}
        >
          <div
            class="rectangle"
            style={{
              left: "-6px",
              top: "6px",
              position: "absolute",
              height: "1.2rem",
              width: "3px",
              backgroundColor: secondaryTeamColor
                ? secondaryTeamColor
                : "#0040a8",
            }}
          />
          <div
            style={{
              width: "100%",
              fontSize: "1.3rem",
              paddingBottom: ".5rem",
            }}
          >
            ALL R.
          </div>
          {AllRounder()}
        </Col>
      </Row>
      {/* <Table
        size="sm"
        className="table-row team-cards-table"
        style={{
          width: "85%",
          color: "black",
          marginBottom: "0.3rem",
        }}
      >
        <thead>
          <tr>
            <th>BATTERS</th>
            <th>WK</th>
          </tr>
        </thead>
        <tbody>
          <Row1 />
        </tbody>
      </Table> */}
      {/* <Table
        size="sm"
        className=" table-fixed team-cards-table"
        style={{
          width: "85%",
          color: "black",
          marginBottom: "0rem",
        }}
      >
        <thead>
          <tr>
            <th>BOWLERS</th>
            <th>ALL ROUNDERS</th>
          </tr>
        </thead>
        <tbody>
          <Row2 />
        </tbody>
      </Table> */}
    </DialogBox>
  );
};

export default TeamCards;
