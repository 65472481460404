import "./index.scss";
import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap/";
import jahaj from "../../resources/images/arshdeep.png";
import ViewPlayersTable from "../viewPlayersTable";
import { useSelector } from "react-redux";

function stringifyNumber(n) {
  var special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  var deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
}

const ViewPlayers = ({ keyState, blocks, isPortraitMode }) => {
  let biddingBlocks = blocks;
  let { key, setKey } = keyState;

  return (
    <Tab.Container
      activeKey={key}
      onSelect={(k) => setKey(k)}
      id="left-tabs"
      style={{ height: "100%" }}
      // defaultActiveKey="first"
    >
      <Row style={{ height: "inherit" }}>
        {!isPortraitMode && (
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {/* {biddingBlocks?.elite && Object.keys(biddingBlocks).map(block => )} */}
              {biddingBlocks?.elite && (
                <Nav.Item>
                  <Nav.Link eventKey="elite">Elite</Nav.Link>
                </Nav.Item>
              )}
              {biddingBlocks?.marquee && (
                <Nav.Item>
                  <Nav.Link eventKey="marquee">MARQUEE</Nav.Link>
                </Nav.Item>
              )}
              {biddingBlocks?.Batsman && (
                <Nav.Item>
                  <Nav.Link eventKey="Batsman">BATSMAN</Nav.Link>
                </Nav.Item>
              )}
              {biddingBlocks?.["All Rounder"] && (
                <Nav.Item>
                  <Nav.Link eventKey="All Rounder">ALL-ROUNDER</Nav.Link>
                </Nav.Item>
              )}
              {biddingBlocks?.Bowler && (
                <Nav.Item>
                  <Nav.Link eventKey="Bowler">BOWLER</Nav.Link>
                </Nav.Item>
              )}
              {biddingBlocks?.Wicketkeeper && (
                <Nav.Item>
                  <Nav.Link eventKey="Wicketkeeper">WICKETKEEPERS</Nav.Link>
                </Nav.Item>
              )}
              {biddingBlocks?.uncapped && (
                <Nav.Item>
                  <Nav.Link eventKey="uncapped">UNCAPPED</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Col>
        )}

        <Col sm={9} style={{ height: "100%" }}>
          <Tab.Content style={{ height: "100%" }}>
            {biddingBlocks?.elite && (
              <Tab.Pane eventKey="elite">
                <ViewPlayersTable players={biddingBlocks["elite"][0]} />
              </Tab.Pane>
            )}
            {biddingBlocks?.marquee && (
              <Tab.Pane eventKey="marquee">
                <ViewPlayersTable players={biddingBlocks["marquee"][0]} />
              </Tab.Pane>
            )}
            {biddingBlocks?.Batsman && (
              <Tab.Pane eventKey="Batsman">
                <ViewPlayersTable players={biddingBlocks["Batsman"][0]} />
              </Tab.Pane>
            )}
            {biddingBlocks?.["All Rounder"] && (
              <Tab.Pane eventKey="All Rounder">
                <ViewPlayersTable players={biddingBlocks["All Rounder"][0]} />
              </Tab.Pane>
            )}
            {biddingBlocks?.Bowler && (
              <Tab.Pane eventKey="Bowler">
                <ViewPlayersTable players={biddingBlocks["Bowler"][0]} />
              </Tab.Pane>
            )}
            {biddingBlocks?.Wicketkeeper && (
              <Tab.Pane eventKey="Wicketkeeper">
                <ViewPlayersTable players={biddingBlocks["Wicketkeeper"][0]} />
              </Tab.Pane>
            )}
            {biddingBlocks?.uncapped && (
              <Tab.Pane eventKey="uncapped">
                <ViewPlayersTable players={biddingBlocks["uncapped"][0]} />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default ViewPlayers;
