import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const { Bidder } = require("../networking");

export const login = createAsyncThunk("bidder/login", async (data) => {
  const response = await Bidder.login(data);
  return response;
});

export const getProfile = createAsyncThunk("bidder/getProfile", async () => {
  const response = await Bidder.getProfile();
  return response;
});

export const editProfile = createAsyncThunk(
  "bidder/editProfile",
  async (data, id) => {
    const response = await Bidder.editProfile(data);
    return response;
  }
);

export const logout = createAsyncThunk("bidder/logout", async () => {
  const response = await Bidder.logout();
  return response;
});

// options

export const boughtPlayers = createAsyncThunk(
  "bidder/boughtPlayers",
  async (id, data) => {
    const response = await Bidder.boughtPlayers();
    return response;
  }
);

export const lastYearPlayer = createAsyncThunk(
  "bidder/lastYearPlayer",
  async (id, data) => {
    const response = await Bidder.lastYearPlayer();
    return response;
  }
);

export const bid = createAsyncThunk("bidder/bid", async (data) => {
  const response = await Bidder.bid(data);
  return response;
});

export const rtm = createAsyncThunk("bidder/bid", async (data) => {
  const response = await Bidder.rtm(data);
  return response;
});

export const purseremaining = createAsyncThunk(
  "bidder/purseremaining",
  async () => {
    const response = await Bidder.purseremaining();
    return response;
  }
);

export const activeBidding = createAsyncThunk(
  "bidder/activeBidding",
  async () => {
    const response = await Bidder.activeBidding();
    return response;
  }
);

export const activeBlock = createAsyncThunk("bidder/activeBlock", async () => {
  const response = await Bidder.activeBlock();
  return response;
});

export const getActiveSession = createAsyncThunk(
  "bidder/getActiveSession",
  async (data) => {
    const response = await Bidder.getSession(data);
    return response;
  }
);

export const BiddingBlocks = createAsyncThunk(
  "general/BiddingBlocks",
  async (id, data) => {
    const response = await Bidder.BiddingBlocks();
    return response;
  }
);

export const blockStatus = createAsyncThunk("bidder/blockStatus", async () => {
  const response = await Bidder.blockStatus();
  return response;
});

const bidderSlice = createSlice({
  name: "bidder",
  initialState: {
    status: "",
    boughtPlayersData: [],
    lastYearPlayersData: [],
    teamName: "",
    activeBlock: {},
    purseremaining: {},
    boughtFilteredPlayers: {},
    isLoggedIn: true,
    LoginStatus: "waiting",
    biddingBlocks: [],
    session: {},
    info: {},
    activeBidding: {},
    errorString: "",
    blockStatus: "",
    error: "",
  },
  reducers: {
    // logout: (state, action) => {},
  },

  extraReducers: {
    [login.pending]: (state) => {
      state.status = "waiting";
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.info = data;
        localStorage.setItem("bidderToken", data.token);
        state.isLoggedIn = true;
        state.status = "Logged in!";
      } else if (statusCode !== 200) {
        state.errorString = "No Data!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.status = "failed";
      state.error = action.error;
    },
    [getProfile.pending]: (state) => {
      state.status = "waiting";
    },
    [getProfile.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.info = data;
        state.isLoggedIn = true;
        state.LoginStatus = "success";
        state.status = "success";
      } else if (statusCode !== 200) {
        state.errorString = "Not Logged in!";
        state.status = "failed";
        state.LoginStatus = "failed";
        state.isLoggedIn = false;
      } else {
        state.LoginStatus = "failed";
        state.errorString = "Login first";
      }
    },
    [getProfile.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.LoginStatus = "failed";
      state.status = "failed";
      state.error = action.error;
    },
    [editProfile.pending]: (state) => {
      state.status = "waiting";
    },
    [editProfile.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.info = data;
        state.status = "Edited Bidder Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Network Error!";
        state.status = "Failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [editProfile.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [logout.pending]: (state) => {
      state.status = "waiting";
    },
    [logout.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      localStorage.setItem("bidderToken", "");
      if (statusCode === 200) {
        state.info = {};
        state.isLoggedIn = false;
        state.status = "Logged Out!";
      } else if (statusCode !== 200) {
        state.info = {};
        state.errorString = "Network Error!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [logout.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },

    [boughtPlayers.pending]: (state) => {
      state.status = "waiting";
    },
    [boughtPlayers.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        let filteredPlayers = {
          Batsman: [],
          Bowler: [],
          Wicketkeeper: [],
          ["All Rounder"]: [],
          Uncapped: [],
          Indians: [],
          Overseas: [],
        };
        data?.players?.forEach((playerData) => {
          if (playerData?.Player) {
            filteredPlayers[playerData?.Player?.role].push(playerData);
            if (playerData?.Player?.uncapped) {
              filteredPlayers["Uncapped"].push(playerData);
            }
            if (playerData?.Player?.nationality?.toLowerCase() === "india") {
              filteredPlayers["Indians"].push(playerData);
            } else {
              filteredPlayers["Overseas"].push(playerData);
            }
          }
        });

        state.teamName = data?.name;
        state.boughtFilteredPlayers = filteredPlayers;
        state.boughtPlayersData = data;
        state.status = "Fetched Player Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [boughtPlayers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },

    [lastYearPlayer.pending]: (state) => {
      state.status = "waiting";
    },
    [lastYearPlayer.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.lastYearPlayersData = data;
        state.status = "Fetched Player Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [lastYearPlayer.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [bid.pending]: (state) => {
      state.status = "waiting";
    },
    [bid.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Bidded Data!";
      } else if (statusCode !== 200) {
        state.errorString = action.payload?.msg;
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [bid.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [rtm.pending]: (state) => {
      state.status = "waiting";
    },
    [rtm.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "RTMed Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [rtm.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [purseremaining.pending]: (state) => {
      state.status = "waiting";
    },
    [purseremaining.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.purseremaining = data;
        state.status = "Fetched Purse Remaining Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [purseremaining.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [activeBidding.pending]: (state) => {
      state.status = "waiting";
    },
    [activeBidding.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.activeBidding = data;
        state.status = "Fetched Active Bidding Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [activeBidding.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [activeBlock.pending]: (state) => {
      state.status = "waiting";
    },
    [activeBlock.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.activeBlock = data;
        state.status = "Fetched Active Block Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [activeBlock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [getActiveSession.pending]: (state) => {
      state.status = "waiting";
    },
    [getActiveSession.fulfilled]: (state, action) => {
      const { statusCode, data } = action.payload;
      if (statusCode === 200) {
        state.session = data;
      } else if (statusCode !== 200) {
        state.error = "No Data!";
        state.session = {};
      } else {
        state.error = "Login first";
      }
    },
    [getActiveSession.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [BiddingBlocks.pending]: (state) => {
      state.status = "waiting";
    },
    [BiddingBlocks.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.biddingBlocks = data;
        state.status = "Fetched Bidding Blocks Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [BiddingBlocks.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [blockStatus.pending]: (state) => {
      state.status = "waiting";
    },
    [blockStatus.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.blockStatus = data?.status;
        state.status = "Fetched Active Bidding Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [blockStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
  },
});

export const selectstatus = (state) => state.bidder.status;
export const selectTeamName = (state) => state.bidder.teamName;
export const selectBoughtPlayerData = (state) => state.bidder.boughtPlayersData;
export const selectLastYearPlayersData = (state) =>
  state.bidder.lastYearPlayersData;
export const selectFilteredPlayers = (state) =>
  state.bidder.boughtFilteredPlayers;
export const selectpurseremaining = (state) => state.bidder.purseremaining;
export const selectisLoggedIn = (state) => state.bidder.isLoggedIn;
export const selectLoginStatus = (state) => state.bidder.LoginStatus;
export const selectbiddingBlocks = (state) => state.bidder.biddingBlocks;
export const selectBidderProfile = (state) => state.bidder.info;
export const selectactiveBidding = (state) => state.bidder.activeBidding;
export const selectBlockStatus = (state) => state.bidder.blockStatus;
export const selectactiveBlock = (state) => state.bidder.activeBlock;
export const selectActiveSession = (state) => state.bidder.session;
export const selecterrorString = (state) => state.bidder.errorString;
export const selecterror = (state) => state.bidder.error;

export default bidderSlice.reducer;
