import "./index.scss";
import React from "react";
import DialogBox from "../dialogBox";
import { Col, Row } from "react-bootstrap";
import disappointed from "../../resources/images/depressed.png";
import {
  selectIsSold,
  selectLoadingStatus,
  selectSellingStatus,
} from "../../store/features/auctionSlice";
import { useSelector } from "react-redux";

const TeamBidding = ({ player, hide }) => {
  let isSold = useSelector(selectIsSold);
  let sellingStatus = useSelector(selectSellingStatus);
  let loadingStatus = useSelector(selectLoadingStatus);

  return (
    <>
      {sellingStatus === "no bids" ? (
        <> </>
      ) : (
        <DialogBox
          hide={hide}
          backgroundColor={
            isSold
              ? "#0BD18A"
              : sellingStatus === "unsold"
              ? "#920202"
              : player?.lastBidBy?.franchise?.color?.hex
              ? player?.lastBidBy?.franchise?.color?.hex
              : "#3892FE"
          }
          logo={
            sellingStatus === "unsold"
              ? disappointed
              : `/teams/${player?.lastBidBy?.franchise?.image}`
              ? `/teams/${player?.lastBidBy?.franchise?.image}`
              : ""
          }
          className="teamBidding"
          header={
            isSold ? (
              <span style={{ marginLeft: "1rem", fontSize: "1rem" }}>
                Sold To
              </span>
            ) : (
              <span style={{ marginLeft: "1rem", fontSize: "1rem" }}>
                BID BY
              </span>
            )
          }
          headerStyle={{
            minHeight: "1.9rem",
            justifyContent: "left",
            // maxWidth: "21rem",
          }}
          style={{
            fontFamily: "Poppins-Semibold",
            // maxWidth: "21rem",
          }}
        >
          <Col
            className="col"
            style={{ fontSize: "12px", textAlign: "center" }}
          >
            <Row style={{ width: "100%", justifyContent: "center", margin: 0 }}>
              {sellingStatus === "unsold" ? (
                <span
                  style={{
                    margin: "0.8rem",
                    // width: "9rem",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    fontFamily: "Poppins-Semibold",
                  }}
                >
                  -
                </span>
              ) : (
                <>
                  <span
                    style={{
                      marginTop: "0.4rem",
                      // width: "9rem",
                      fontSize: "1.5rem",
                      textTransform: "uppercase",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {player?.lastBidBy?.team ? player?.lastBidBy?.team : "-"}
                  </span>
                  <span
                    style={{
                      marginBottom: "0.4rem",
                      // width: "9rem",
                      fontSize: "0.8rem",
                      textTransform: "uppercase",
                      fontFamily: "Poppins-LightItalic",
                    }}
                  >
                    {player?.lastBidBy?.owner ? player?.lastBidBy?.owner : "-"}
                  </span>
                </>
              )}
            </Row>
            <Row
              style={{ width: "100%", justifyContent: "center", margin: 0 }}
            ></Row>
          </Col>
        </DialogBox>
      )}
    </>
  );
};

export default TeamBidding;
