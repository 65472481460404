import React from "react";
import "./index.scss";
import meme from "../../../resources/images/meme.png";
import Cbtn from "../../../components/commonbutton/cbtn";
import { useState } from "react";

import ausbaun from "../../../resources/svg/ausbaun.png";
//svg import
import { ReactComponent as Eye } from "../../../resources/svg/Show.svg";
import { ReactComponent as Spectors } from "../../../resources/svg/spectors.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectLoginStatus,
  selectstatus,
} from "../../../store/features/bidderSlice";
import { useLocation, useNavigate } from "react-router";
import Snackbar from "../../../components/snackbar/snackbar";

const BidderLogin = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  let loadingStatus = useSelector(selectstatus);
  let LoginStatus = useSelector(selectLoginStatus);

  const [state, setState] = useState({
    email: "",
    password: "",
    showPass: false,
  });
  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const handleShowPass = () => {
    setState((prevState) => ({
      ...prevState,
      showPass: !prevState.showPass,
    }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.email === "") {
      setError("Invalid Username");
    } else if (state.password === "") {
      setError("Invalid Password");
    } else {
      let resData = await dispatch(
        login({ userQuery: state.email, password: state.password })
      ).unwrap();
      if (resData.statusCode === 200) {
        window.location.href = "/bidder/bidding";
      } else if (resData.statusCode === 400) {
        setError(resData.msg);
        setShowError(true);
      }
    }
  };

  React.useEffect(() => {
    if (LoginStatus === "success") {
      navigate("/bidder/bidding");
    }
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location, LoginStatus]);

  React.useEffect(() => {
    if (loadingStatus === "fulfilled") {
      navigate("/bidder/bidding");
    }
  }, [loadingStatus]);

  return (
    <>
      <div className="biddercontainer">
        <Snackbar
          show={showError}
          setShow={setShowError}
          errorMsg={error}
          location={{ vertical: "bottom", horizontal: "center" }}
        ></Snackbar>
        <div className="up">
          <img src={meme} />
        </div>
        <div className="down">
          <div className="welcome">Welcome to</div>
          <div className="spectors" style={{ marginBotton: "7px" }}>
            {" "}
            Cricket Auction Strategy
          </div>
          <div className="welcome" style={{ marginBottom: "1rem" }}>
            {" "}
            (Bidder)
          </div>
          <div className="form">
            <form className="loginForm" onSubmit={handleSubmit}>
              <div className="inputdiv">
                <div className="inputdiv1">
                  <label>
                    <input
                      type="text"
                      autoFocus
                      placeholder="User ID"
                      id="email"
                      className="input1"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div className="inputdiv2">
                  <label>
                    <input
                      type={state.showPass ? "text" : "password"}
                      autoFocus
                      placeholder="Password"
                      id="password"
                      className="input2"
                      value={state.password}
                      onChange={handleChange}
                    />
                    <span className="eyeSvg">
                      <Eye className="eye" onClick={handleShowPass} />
                    </span>
                  </label>
                </div>
              </div>
              <div className="buttonDiv">
                <Cbtn
                  onClick={handleSubmit}
                  className="huehuehue"
                  type={"submit"}
                  style={{
                    width: "100%",
                    backgroundColor: "#0040a8",
                    borderRadius: "20px",
                  }}
                >
                  LOG IN AS BIDDER
                </Cbtn>
              </div>
              {/* <div>{error && <h6 className="errorex">{error}</h6>}</div> */}
            </form>
          </div>
          <div className="logoContainer">
            <div className="logo">
              <div className="spectorsLogo">
                <Spectors />
              </div>
              <div className="ausabaunLogo">
                <img src={ausbaun} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidderLogin;
