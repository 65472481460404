import "./index.scss";
import jahag from "../../resources/images/arshdeep.png";
import React from "react";
import DialogBox from "../dialogBox";
import { Col, Row, Table } from "react-bootstrap";
import {
  selectIsSold,
  selectLoadingStatus,
  selectSellingStatus,
} from "../../store/features/auctionSlice";
import { useSelector } from "react-redux";

const PlayerDetails = ({ player, isCurator, className, hide }) => {
  let isSold = useSelector(selectIsSold);
  let sellingStatus = useSelector(selectSellingStatus);
  let loadingStatus = useSelector(selectLoadingStatus);

  let name = player?.name?.split(" ");
  let initial = name && name[0][0];
  let lastName = name?.slice(1, name.length)?.join(" ");

  return (
    <DialogBox
      hide={hide}
      header={
        <span
          style={{
            position: "relative",
            fontSize: "1.3rem",
          }}
        >
          {player?.nationality &&
            !(player?.nationality?.toLowerCase() === "india") && (
              <img
                src={jahag}
                style={{
                  position: "absolute",
                  top: 6,
                  left: -27,
                  width: "1.1rem",
                  margin: "0 0.7rem 0 0",
                }}
                alt="jahaj"
              />
            )}
          {isCurator
            ? player?.name
            : initial
            ? `${initial}. ${String(lastName)}`
            : "-"}
        </span>
      }
      headerStyle={{
        justifyContent: "left",
        textAlign: "center",
      }}
      style={{ fontFamily: "Poppins-Semibold" }}
    >
      <Col className="col" style={{ fontSize: "0.9rem", textAlign: "center" }}>
        <Row style={{ width: "100%", justifyContent: "center", margin: 0 }}>
          <span
            style={{
              margin: "0.4rem",
              width: "9rem",
              fontSize: "0.9rem",
              textTransform: "uppercase",
              fontFamily: "Poppins-Semibold",
              borderBottom: "0.5px solid #9E9DD3",
            }}
          >
            {player?.role || "-"}
          </span>
          <div style={{ fontWeight: "900", fontFamily: "Poppins-SemiBold" }}>
            {`${player?.rating}`}
          </div>
        </Row>
        <Row style={{ width: "100%", justifyContent: "center", margin: 0 }}>
          <Table
            size="sm"
            className="purse-table"
            style={{
              width: "95%",
              color: "#F9F8F8",
              marginBottom: "0.5rem",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    border: "none",
                    fontFamily: "Poppins-Thin",
                  }}
                >
                  ROLE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "left", border: "none" }}>
                  {player?.battingHanded ? player?.battingHanded : "-"}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left", border: "none" }}>
                  {player?.bowlingHanded ? player?.bowlingHanded : "-"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row
          style={{
            backgroundColor: "#3892FE",
            width: "100%",
            margin: 0,
            padding: "0.6rem 0 0.6rem 0",
          }}
        >
          <Col
            style={{
              fontFamily: "Poppins-Regular",
              fontSize: "1.2rem",
              alignItems: "center",
            }}
          >
            BASE PRICE
          </Col>
          <Col
            style={{
              fontFamily: "Poppins-Semibold",
              fontSize: "1.3rem",
              textAlign: "right",
              paddingRight: "1.6rem",
            }}
          >
            {player?.basePrice ? `${player?.basePrice}L` : "-"}
          </Col>
        </Row>
      </Col>
    </DialogBox>
  );
};

export default PlayerDetails;
