import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const { Curator } = require("../networking");

export const login = createAsyncThunk("curator/login", async (data) => {
  const response = await Curator.login(data);
  return response;
});

export const getProfile = createAsyncThunk(
  "curator/getProfile",
  async (id, data) => {
    const response = await Curator.getProfile();
    return response;
  }
);

export const editProfile = createAsyncThunk(
  "curator/editProfile",
  async (id, data) => {
    const response = await Curator.editProfile();
    return response;
  }
);

export const logout = createAsyncThunk("curator/logout", async () => {
  const response = await Curator.logout();
  return response;
});

// options

export const players = createAsyncThunk("curator/players", async () => {
  const response = await Curator.players();
  return response;
});

export const teams = createAsyncThunk("curator/team", async () => {
  const response = await Curator.teams();
  return response;
});

export const franchises = createAsyncThunk("curator/franchises", async () => {
  const response = await Curator.franchises();
  return response;
});

export const mostExpPlayer = createAsyncThunk(
  "curator/mostExpPlayer",
  async () => {
    const response = await Curator.mostExpPlayer();
    return response;
  }
);

export const lastSoldPlayer = createAsyncThunk(
  "curator/lastSoldPlayer",
  async () => {
    const response = await Curator.lastSoldPlayer();
    return response;
  }
);

export const purseremaining = createAsyncThunk(
  "curator/purseremaining",
  async (id, data) => {
    const response = await Curator.purseremaining();
    return response;
  }
);

export const activeBidding = createAsyncThunk(
  "curator/activeBidding",
  async (id, data) => {
    const response = await Curator.activeBidding();
    return response;
  }
);

export const activeBlock = createAsyncThunk("curator/activeBlock", async () => {
  const response = await Curator.activeBlock();
  return response;
});

export const BiddingBlocks = createAsyncThunk(
  "curator/BiddingBlocks",
  async () => {
    const response = await Curator.BiddingBlocks();
    return response;
  }
);

export const startBlock = createAsyncThunk(
  "curator/startBlock",
  async (data) => {
    const response = await Curator.startBlock(data);
    return response;
  }
);

export const finishBlock = createAsyncThunk("curator/finishBlock", async () => {
  const response = await Curator.finishBlock();
  return response;
});

export const resetBid = createAsyncThunk("curator/resetBid", async () => {
  const response = await Curator.resetBid();
  return response;
});

export const stopBlock = createAsyncThunk("curator/stopBlock", async () => {
  const response = await Curator.stopBlock();
  return response;
});

export const stopBidding = createAsyncThunk("curator/stopBidding", async () => {
  const response = await Curator.stopBidding();
  return response;
});

export const startBidding = createAsyncThunk(
  "curator/startBidding",
  async () => {
    const response = await Curator.startBidding();
    return response;
  }
);

export const createBid = createAsyncThunk("curator/createBid", async () => {
  const response = await Curator.createBid();
  // console.log(response);
  return response;
});

export const setPlayerUnsold = createAsyncThunk(
  "curator/setPlayerUnsold",
  async (id, data) => {
    const response = await Curator.setPlayerUnsold();
    return response;
  }
);

export const setPlayerSold = createAsyncThunk(
  "curator/setPlayerSold",
  async (id, data) => {
    const response = await Curator.setPlayerSold();
    return response;
  }
);

export const setPlayerSoldCustom = createAsyncThunk(
  "curator/setPlayerSoldCustom",
  async (data) => {
    const response = await Curator.setPlayerSoldCustom(data);
    return response;
  }
);

export const bidStatus = createAsyncThunk("curator/bidStatus", async (data) => {
  const response = await Curator.bidStatus(data);
  return response;
});

export const getActiveSession = createAsyncThunk(
  "curator/getActiveSession",
  async (data) => {
    const response = await Curator.getSession(data);
    return response;
  }
);

const curatorSlice = createSlice({
  name: "curator",
  initialState: {
    status: "",
    teams: [],
    mostExpPlayer: {},
    lastSoldPlayer: {},
    playerData: {},
    startBlock: {},
    activeBlock: {},
    franchises: [],
    biddingBlocks: [],
    session: {},
    purseremaining: {},
    isLoggedIn: false,
    info: {},
    activeBidding: {},
    errorString: "",
    error: "",
  },
  reducers: {
    // logout: (state, action) => {},
  },

  extraReducers: {
    [login.pending]: (state) => {
      state.status = "waiting";
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(data);
      if (statusCode === 200 && data) {
        state.info = data;
        localStorage.setItem("curatorToken", data.token);
        state.isLoggedIn = true;
        state.status = "Logged in!";
      } else if (statusCode !== 200) {
        state.errorString = "No Data!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.status = "failed";
      state.error = action.error;
    },
    [getProfile.pending]: (state) => {
      state.status = "waiting";
    },
    [getProfile.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.info = data;
        state.isLoggedIn = true;
        state.status = "success";
      } else if (statusCode !== 200) {
        state.errorString = "Not Logged in!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [getProfile.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.status = "failed";
      state.error = action.error;
    },
    [editProfile.pending]: (state) => {
      state.status = "waiting";
    },
    [editProfile.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.info = data;
        state.status = "Edited Curator Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Network Error!";
        state.status = "Failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [editProfile.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [logout.pending]: (state) => {
      state.status = "waiting";
    },
    [logout.fulfilled]: (state, action) => {
      localStorage.setItem("curatorToken", "");
      const { data, statusCode } = action.payload;

      state.info = {};
      state.isLoggedIn = false;
      state.status = "Logged Out!";
      if (statusCode !== 200) {
        state.info = {};
        state.errorString = "Network Error!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [logout.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [players.pending]: (state) => {
      state.status = "waiting";
    },
    [players.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.playerData = data;
        state.status = "Fetched Player Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [players.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [teams.pending]: (state) => {
      state.status = "waiting";
    },
    [teams.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;

      if (statusCode === 200 && data) {
        let teams = data.map((teamData) => {
          let { name, players } = teamData;
          let filteredPlayers = {
            Batsman: [],
            Bowler: [],
            Wicketkeeper: [],
            ["All Rounder"]: [],
            Uncapped: [],
            Indians: [],
            Overseas: [],
          };
          players?.forEach((playerData) => {
            if (playerData?.Player) {
              filteredPlayers[playerData?.Player?.role].push(playerData);
              if (playerData?.Player?.uncapped) {
                filteredPlayers["Uncapped"].push(playerData);
              }
              if (playerData?.Player?.nationality?.toLowerCase() === "india") {
                filteredPlayers["Indians"].push(playerData);
              } else {
                filteredPlayers["Overseas"].push(playerData);
              }
            }
          });
          return { ...teamData, name, filteredPlayers };
        });

        // state.boughtFilteredPlayers = filteredPlayers;
        state.teams = teams;
        state.status = "Fetched Player Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [teams.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [franchises.pending]: (state) => {
      state.status = "waiting";
    },
    [franchises.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;

      if (statusCode === 200 && data) {
        state.franchises = data;
        state.status = "Fetched Player Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [franchises.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [mostExpPlayer.pending]: (state) => {
      state.status = "waiting";
    },
    [mostExpPlayer.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.mostExpPlayer = data;
        state.status = "Fetched Most Expensive Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [mostExpPlayer.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [lastSoldPlayer.pending]: (state) => {
      state.status = "waiting";
    },
    [lastSoldPlayer.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.lastSoldPlayer = data;
        state.status = "Fetched Most Expensive Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [lastSoldPlayer.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [purseremaining.pending]: (state) => {
      state.status = "waiting";
    },
    [purseremaining.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.purseremaining = data;
        state.status = "Fetched Purse Remaining Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [purseremaining.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [BiddingBlocks.pending]: (state) => {
      state.status = "waiting";
    },
    [BiddingBlocks.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.biddingBlocks = data;
        state.status = "Fetched Bidding Blocks Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [BiddingBlocks.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [activeBidding.pending]: (state) => {
      state.status = "waiting";
    },
    [activeBidding.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.activeBidding = data;
        state.status = "Fetched Active Bidding Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [activeBidding.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [activeBlock.pending]: (state) => {
      state.status = "waiting";
    },
    [activeBlock.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.activeBlock = data;
        state.status = "Fetched Active Block Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [activeBlock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [startBlock.pending]: (state) => {
      state.status = "waiting";
    },
    [startBlock.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Started Block!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [startBlock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [finishBlock.pending]: (state) => {
      state.status = "waiting";
    },
    [finishBlock.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Finished Block!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [finishBlock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [stopBlock.pending]: (state) => {
      state.status = "waiting";
    },
    [stopBlock.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Stopped Block!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [stopBlock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [createBid.pending]: (state) => {
      state.status = "waiting";
    },
    [createBid.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Created Bid!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [createBid.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },

    [resetBid.pending]: (state) => {
      state.status = "waiting";
    },
    [resetBid.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Player Resetted!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [resetBid.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [setPlayerUnsold.pending]: (state) => {
      state.status = "waiting";
    },
    [setPlayerUnsold.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Player Sold";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [setPlayerUnsold.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [setPlayerSold.pending]: (state) => {
      state.status = "waiting";
    },
    [setPlayerSold.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Player Sold";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [setPlayerSold.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [setPlayerSoldCustom.pending]: (state) => {
      state.status = "waiting";
    },
    [setPlayerSoldCustom.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Player Sold!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [setPlayerSoldCustom.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [bidStatus.pending]: (state) => {
      state.status = "waiting";
    },
    [bidStatus.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (statusCode === 200) {
        state.status = "Player Sold";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [bidStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [getActiveSession.pending]: (state) => {
      state.status = "waiting";
    },
    [getActiveSession.fulfilled]: (state, action) => {
      const { statusCode, data } = action.payload;
      if (statusCode === 200) {
        state.session = data;
      } else if (statusCode !== 200) {
        state.error = "No Data!";
        state.session = {};
      } else {
        state.error = "Login first";
      }
    },
    [getActiveSession.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
  },
});

export const selectstatus = (state) => state.curator.status;
export const selectmostExpPlayer = (state) => state.curator.mostExpPlayer;
export const selectLastSoldPlayer = (state) => state.curator.lastSoldPlayer;
export const selectplayerData = (state) => state.curator.playerData;
export const selectTeamsData = (state) => state.curator.teams;
export const selectFranchisesData = (state) => state.curator.franchises;
export const selectstartBlock = (state) => state.curator.startBlock;
export const selectbiddingBlocks = (state) => state.curator.biddingBlocks;
export const selectpurseremaining = (state) => state.curator.purseremaining;
export const selectisLoggedIn = (state) => state.curator.isLoggedIn;
export const selectinfo = (state) => state.curator.info;
export const selectActiveSession = (state) => state.curator.session;
export const selectactiveBidding = (state) => state.curator.activeBidding;
export const selectactiveBlock = (state) => state.curator.activeBlock;
export const selecterrorString = (state) => state.curator.errorString;
export const selecterror = (state) => state.curator.error;

export default curatorSlice.reducer;
